import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { Resume } from "../components/Resume";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Button = makeShortcode("Button");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Resume`}</h1>
    <Button as="a" variant="secondary" href="/akshay-resume.pdf" sx={{
      mr: 2
    }} mdxType="Button">
  Download PDF
    </Button>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <Resume file="/akshay-resume.pdf" mdxType="Resume" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      